<template>
  <div class="solution-item__content" :data-aos ="solution.animation">
    <div class="solution-item__pic-container inline">
      <picture>
        <source
          :srcset="require(`@/assets/images/home/solution/${solution.path}.webp`)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/home/solution/${solution.path}.png`)"
          type="image/jpeg"
        />
        <img
          class="solution-item__pic"
          :src="require(`@/assets/images/home/solution/${solution.path}.png`)"
          alt="solution-item__pic"
          width="200px"
          height="154px"
        />
      </picture>
    </div>
    <div class="solution-item__words inline">
      <div class="solution-item__title-container">
        <span class="solution-item__title">{{ solution.title }}</span>
      </div>
      <span class="solution-item__desc">{{ solution.description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["solution"],
};
</script>

<style scoped>
.inline {
  display: inline-block;
  vertical-align: middle;
}
.solution-item__pic-container {
  width: 30%;
  height: auto;
}
.solution-item__pic {
  width: 100%;
  height: auto !important;
}
.solution-item__words {
  text-align: left;
  width: 60%;
  padding-left: 15px;
  font-size: 13.5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.solution-item__title {
  font-weight: 900;
}
@media only screen and (min-width: 1024px) {
  .inline {
    display: block;
  }
  .solution-item__content {
    display: inline-block;
    width: 25%;
    margin-left: 2vw;
    margin-right: 2vw;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    height: 400px;
    vertical-align: middle;
    width: 280px;
  }
  .solution-item__pic-container {
    width: 100%;
    padding-top: 55px;
  }
  .solution-item__pic {
    width: 200px;
  }
  .solution-item__words {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    font-size: 15px;
  }
  .solution-item__title {
    font-weight: 700;
    font-size: 23px;
  }
  .solution-item__title-container {
    padding-bottom: 10px;
  }
}
</style>
