<template>
  <div class="testimonial__item" data-aos="zoom-in">
    <div class="testimonial__words inline">
      <span class="quotes">&quot;</span>
      <span class="testimonial__desc">{{ testimonial.description }}</span>
    </div>
    <div class="testimonial__words2 inline">
      <div class="testimonial__title__container">
        <span class="service__title">{{ testimonial.title }}</span>
      </div>
      <span class="testimonial__desc">{{ testimonial.company }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["testimonial"],
};
</script>

<style scoped>
.testimonial__title__container {
  padding: 20px 0px 10px 0px;
}
.testimonial__item {
  padding: 55px 30px;
  background-color: white;
  border-radius: 50%;
  width: 340px;
  height: 280px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.inline {
  display: inline-block;
  vertical-align: middle;
}
.testimonial__words,
.testimonial__words2 {
  position: relative;
  text-align: center;
  line-height: 1.8;
  width: 95%;
  padding-left: 8px;
  font-size: 13.5px;
  padding-top: 60px;
}
.testimonial__words2 {
  padding-top: 0px;
  padding-bottom: 20px;
}
.inline {
  display: block;
}

.testimonial__desc {
  position: relative;
  z-index: 2;
  font-weight: 600;
}

.testimonial__words .quotes {
  font-size: 150px;
  color: lightgray;
  position: absolute;
  top: -30px;
  left: 0;
  z-index: 0;
}
.service__title {
  font-weight: 900;
}
.service__title-container {
  padding: 20px 0px;
}

.service__title {
  font-weight: 700;
  font-size: 17px;
}
@media only screen and (max-width: 1450px) {
  .testimonial__item {
    margin-top: -50px;
  }
}
@media only screen and (max-width: 1280px) {
  .testimonial__words,
  .testimonial__words2 {
    font-size: 11.5px;
  }
  .testimonial__item {
    padding: 60px 30px;
    width: 310px;
    height: 245px;
  }
}

@media only screen and (max-width: 1120px) {
  .testimonial__item {
    padding: 0px 30px;
    background-color: white;
    border-radius: 35px;
    width: 100%;
    height: 100%;
  }
  .testimonial__words {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 0px;
    font-size: 13.5px;
  }
  .testimonial__words .quotes {
    display: none;
  }
  .testimonial__words2 {
    text-align: right;
    font-size: 13.5px;
  }
  .testimonial__title__container {
    padding: 20px 0px 0px 0px;
  }
}
</style>