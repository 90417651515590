import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./assets/global_style.scss";
import AOS from "aos";
import "aos/dist/aos.css";

library.add(
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faLinkedin,
  faQuestionCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  created() {
    AOS.init({
      duration:1000
    });
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
