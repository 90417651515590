<template>
  <div>
    <section id="hero" class="hero">
      <div class="hero__content">
        <div class="hero__picture-container">
          <picture id="hero__picture" class="bounce-2" data-aos="fade-up">
            <!-- <source
            :srcset="
              require(`@/assets/images/home/${HeroImgSrc}.webp`)
            "
            type="image/webp"
          /> -->
            <source
              :srcset="require(`@/assets/images/home/${HeroImgSrc}.png`)"
              type="image/png"
            />
            <img
              class="hero__picture"
              :src="require(`@/assets/images/home/${HeroImgSrc}.png`)"
              alt="product-pic"
              width="500px"
              height="401px"
            />
          </picture>
        </div>
        <div class="hero__words"  data-aos="fade-up">
          <div class="hero__title-container">
            <span class="hero__title">
              WebBricks has you covered brick by brick 
            </span>
          </div>
          <div class="hero__desc-container">
            <span>
              Empowering local businesses with the digital presence and solutions they need to succeed.</span
            >
          </div>
          <div class="hero__button-container">
            <GradButton
              class="hero__button"
              title="GET STARTED NOW"
              link="/#ContactUs"
            />
          </div>
        </div>
      </div>
    </section>
    <picture>
      <source
        media="(max-width: 500px)"
        :srcset="require(`@/assets/images/home/hero/hero-mobile.webp`)"
        type="image/webp"
      />
      <source
        media="(max-width: 500px)"
        :srcset="require(`@/assets/images/home/hero/hero-mobile.png`)"
        type="image/png"
      />
      <source
        :srcset="require(`@/assets/images/home/hero/hero-web.webp`)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/home/hero/hero-web.png`)"
        type="image/png"
      />
      <img
        :src="require(`@/assets/images/footer/footer-web.png`)"
        alt="Footer curve"
        class="hero__curve"
      />
    </picture>
  </div>
</template>

<script>
import GradButton from "@/components/GradButton.vue";
export default {
  name: "Hero",
  components: {
    GradButton,
  },
  data() {
    return {
      HeroImgSrc: "Hero_Img",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero__curve {
  width: 100%;
}
.hero__picture-container {
  display: flex;
}
#hero__picture {
  display: block;
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
}
.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.hero {
  background-color: #37484f;
  width: 100%;
}
.hero__button {
  margin: auto;
}
.hero__picture {
  padding-top: 110px;
  padding-bottom: 30px;
  width: 100% !important;
  height: auto !important;
  max-width: 300px;
}
.hero__content {
  margin-left: 10vw;
  margin-right: 10vw;
}
.hero__words {
  color: white;
  text-align: left;
  padding-bottom: 60px;
}
.hero__title {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 35px;
}
.hero__desc-container {
  padding-top: 20px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 1024px) {
  .hero__picture-container,
  .hero__words {
    display: inline-block;
    vertical-align: middle;
    width: 40%;
  }
  .hero__content {
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: row;
  }
  .hero__words {
    margin-top: 5%;
    order: 1;
    padding: 0;
    width: 40%;
  }
  .hero__picture-container {
    padding-left: 10%;
    order: 2;
  }
  .hero__title {
    font-size: 55px;
  }
  .hero__picture {
    width: 100% !important;
    height: auto !important;
    max-width: 500px;
  }
  .hero__button {
    margin: 0;
  }
}
</style>
