<template>
  <div class="gtscore">
    <picture>
      <source
        :srcset="require(`@/assets/images/project/GTmetrix-logo.webp`)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/project/GTmetrix-logo.png`)"
        type="image/jpeg"
      />
      <img
        :src="require(`@/assets/images/project/GTmetrix-logo.png`)"
        alt="gtmetrix logo"
        width="92px"
        height="26px"
      />
    </picture>
    <picture>
      <source
        :srcset="require(`@/assets/images/project/GTmetrix-A.webp`)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/project/GTmetrix-A.png`)"
        type="image/jpeg"
      />
      <img
        class="gtscore__score"
        :src="require(`@/assets/images/project/GTmetrix-A.png`)"
        alt="gtmetrix score"
        width="60px"
        height="58px"
      />
    </picture>
    <div class="gtscore__num-container">
      <div class="gtscore__num-container">
        <p class="gtscore__num">{{ perf }}%</p>
        <p class="gtscore__num gtscore__num--subtitle">PERFORMANCE</p>
      </div>
      <div class="gtscore__num-container">
        <p class="gtscore__num">{{ struct }}%</p>
        <p class="gtscore__num gtscore__num--subtitle">STRUCTURE</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["perf", "struct"],
};
</script>

<style lang="scss" scoped>
.gtscore {
  margin-bottom: 1rem;
}
.gtscore__score {
  padding: 0 0.5rem;
}
.gtscore__num-container {
  display: inline-block;
}
.gtscore__num {
  color: #00a99d;
  font-size: 2.5rem;
  margin: 0;
  padding: 0 0.5rem;
  &--subtitle {
    text-align: center;
    color: #777777;
    font-size: 0.7rem;
    font-weight: bold;
  }
}
</style>