<template>
  <div>
    <hero-section />
    <solution-section />
    <project-section />
    <testimonial-section />
    <contact-section />
  </div>
</template>

<script>
// @ is an alias to /src

import HeroSection from "@/components/home/Hero.vue";
import SolutionSection from "@/components/home/solution/SolutionSection.vue";
import ProjectSection from "@/components/home/project/ProjectSection.vue";
import TestimonialSection from "@/components/home/testimonial/TestimonialSection.vue";
import ContactSection from "@/components/home/contact/ContactSection.vue";


export default {
  name: "Home",
  components: {
    HeroSection,
    SolutionSection,
    ProjectSection,
    TestimonialSection,
    ContactSection,

  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "webBricks is a Singapore-based team of web developers and designers whom provide businesses the ability to maximise customer engagement through web design and development",
      },
    ],
  },
};
</script>
