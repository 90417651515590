<template>
  <section id="project" class="solution">
    <div class="solution__content">
      <div class="solution__title-container">
        <span class="solution__title"
          >Exciting Websites, Excellent Solutions</span
        >
      </div>

      <SolutionItem 
        v-for="solution in solutions"
        :key="solution.id"
        :solution="solution"
      />
    </div>
  </section>
</template>

<script>
import SolutionItem from "@/components/home/solution/SolutionItem.vue";
export default {
  data() {
    return {
      solutions: [
        {
          id: 1,
          title: "Personalised Design",
          description:
            "Each website we make is uniquely designed just for you. We take great care at finding out exactly what makes your company special and incoporate it into your new website",
          path: "p_d_solution",
          animation: 'fade-down',
        },
        {
          id: 2,
          title: "Solutions That Work",
          description:
            "We don't look at a website as just a means to promote you. We find ways to help your website connect with with your cusomters better, faster and easier.",
          path: "s_t_w_solution",
          animation: 'fade-up',
        },
        {
          id: 3,
          title: "Committed To Cilents",
          description:
            "We are dedicated to serving you beyong the inital project launch. Consistently maintaining and upgrading your website is our piriority",
          path: "c_t_c_solution",
          animation: 'fade-down',
        },
      ],
    };
  },
  name: "solution",
  components: {
    SolutionItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.solution {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}

.solution__content {
  margin-left: 5vw;
  margin-right: 5vw;
}
.solution__title-container {
  padding-bottom: 30px;
}
.solution__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.hero__desc-container {
  padding-top: 20px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 1024px) {
  .solution__title {
    font-size: 35px;
    font-weight: 700;
  }
  .solution__title-container {
    text-align: left;
  }
  .solution__content {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}
</style>
